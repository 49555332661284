import React from 'react'
import { graphql, navigate, Link } from 'gatsby'
import classnames from 'classnames'
import { Container, Row, Col, Button } from 'reactstrap'
import Layout from '../components/layout'
import Seo from '../components/shared/Seo'
import Icons from '../components/shared/Icons'
import Legal from '../components/shared/Legal'
import Slugify from '../components/sluggify'
import styled from 'styled-components'

const HtmlArea = styled.div`
  p {
    font-size: 1.5em;
  }
`

class ProductsPage extends React.Component {
  render() {
    const {
      title,
      text,
      image,
      aboutTitle,
      aboutText,
      products,
    } = this.props.data.products

    return (
      <Layout>
        <Seo {...this.props.data.seo} />
        <div className="bg-dark">
          {' '}
          <Container className="py-5">
            {' '}
            <h1 className="mb-4 h2 text-gold  text-center">{title}</h1>
            <Row>
              <Col
                md="6"
                className="d-flex flex-column justify-content-between"
              >
                <HtmlArea
                  className="text-lg-left text-justfy text-gold"
                  dangerouslySetInnerHTML={{ __html: text.g.html }}
                />
                <Row>
                  <Col sm={{ size: 8, offset: 2 }} lg={{ offset: 0, size: 8 }}>
                    <Button
                      style={{ height: '60px', lineHeight: 1 }}
                      size="lg"
                      className="px-7 text-white py-3  text-center primary-btn-gradient  "
                    >
                      {' '}
                      Get Started
                    </Button>
                    <Legal dark className="text-center text-md-left" />
                  </Col>
                </Row>
              </Col>

              <Col md="6">
                <img
                  alt={image.description}
                  src={image.file.url}
                  title={image.title}
                  style={{ maxHeight: '400px' }}
                  className={classnames(
                    'img-fluid mb-3 mb-md-0 d-block mx-auto border-40'
                  )}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="py-5">
          <Container>
            <h2 className="text-primary mb-5">{aboutTitle}</h2>
            <div
              className="text-justify"
              dangerouslySetInnerHTML={{ __html: aboutText.g.html }}
            />
          </Container>
        </div>
        {products.map((product, i) => {
          return (
            <div
              key={i}
              className={classnames('py-5', {
                ' bg-world text-white': i % 2 == 0,
              })}
            >
              <Container>
                <Row className="py-3">
                  <Col
                    md={{
                      size: 6,
                      order: product.flipped ? 1 : 0,
                    }}
                    className="d-flex flex-column justify-content-between"
                  >
                    <h3
                      id={Slugify(product.title)}
                      className="mb-3 text-primary text-lg-left text-center"
                    >
                      {product.title}
                    </h3>
                    <div
                      className="text-justify"
                      dangerouslySetInnerHTML={{ __html: product.text.g.html }}
                    />
                    <span>
                      {' '}
                      <Button
                        size="lg"
                        tag={Link}
                        to={product.link}
                        className="text-white py-2 px-5 mb-3 mb-md-0 text-center primary-btn-gradient"
                      >
                        {' '}
                        Get Started
                      </Button>
                    </span>
                  </Col>

                  <Col
                    md={{
                      size: 6,
                    }}
                    className="d-flex align-items-center"
                  >
                    <div
                      className=" mx-auto border-40"
                      title={product.image.title}
                      style={{
                        background: `url(${product.image.file.url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: '50% 50%',
                        backgroundRepeat: 'no-repeat',
                        paddingBottom: '75%',
                        width: '90%',
                        borderRadius: '21px',
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          )
        })}
        {/* {features.map((feature, i) => {
          return (
            <div className="bg-gradient py-5" key={i}>
              <Container>
                <Row className="py-3">
                  <Col
                    md={{
                      size: 5,
                      order: feature.flipped ? 1 : 0,
                    }}
                    className="d-flex flex-column justify-content-between"
                  >
                    <h3
                      id={Slugify(feature.title)}
                      className="mb-3 text-primary text-lg-left text-center"
                    >
                      {feature.title}
                    </h3>
                    <div
                      dangerouslySetInnerHTML={{ __html: feature.text.g.html }}
                    />
                    <span>
                      {' '}
                      <Button
                        size="lg"
                        tag={Link}
                        to="/routemagic/crm-and-sales"
                        className="text-white py-2 px-5 mb-3 mb-md-0 text-center primary-btn-gradient"
                      >
                        {' '}
                        View All Capabilities
                      </Button>
                    </span>
                  </Col>

                  <Col
                    md={{
                      size: 6,
                    }}
                    className="d-flex align-items-center"
                  >
                    <div
                      className=" mx-auto border-40"
                      title={feature.image.title}
                      style={{
                        background: `url(${feature.image.file.url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: '50% 50%',
                        backgroundRepeat: 'no-repeat',
                        paddingBottom: '75%',
                        width: '90%',
                        borderRadius: '21px',
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          )
        })} */}

        <Icons />
      </Layout>
    )
  }
}

export default ProductsPage
export const query = graphql`
  {
    seo: contentfulProductsPage {
      title: pageTitle
      description
      keywords
    }
    products: contentfulProductsPage {
      title
      text {
        g: childMarkdownRemark {
          html
        }
      }
      image {
        title

        description
        file {
          url
        }
      }
      aboutTitle
      aboutText {
        g: childMarkdownRemark {
          html
        }
      }
      products {
        title
        link
        text {
          g: childMarkdownRemark {
            html
          }
        }
        image {
          title
          description
          file {
            url
          }
        }
      }
    }
  }
`
